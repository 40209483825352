const LEAGUES = [
  {
    id: "nfl",
    name: "NFL",
    league_friendly_name: "NFL",
    icon: "Leagues/NFL/NFL.png",
    sport: "football",
  },
  {
    id: "epl",
    name: "EPL",
    league_friendly_name: "Premier League",
    icon: "Leagues/EPL/EPL.png",
    sport: "soccer",
  },
  {
    id: "big10",
    name: "BIG10",
    league_friendly_name: "Big Ten",
    icon: "Leagues/BIG10/BIG10.png",
    sport: "college",
  },
  // { id: "League_MLS", name: "MLS", icon: "Leagues/MLS/MLS.png" },
  // {
  //   id: "League_USATF",
  //   name: "USATF",
  //   league_friendly_name: "US Track & Field",
  //   icon: "Leagues/USATF/USATF.png",
  // },
  // { id: "League_MLB", name: "MLB", icon: "Leagues/MLB/MLB.png" },
];

export default LEAGUES;
