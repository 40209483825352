const icons = require.context("./images/icons", true);

const Icon = ({ icon }) => {
  const iconSrc = icon ? `./${icon}` : "./temp_icon.png";
  const altText = icon?.name || "icon";

  try {
    return <img src={icons(iconSrc)} alt={altText} loading="lazy" />;
  } catch (err) {
    return <img src={icons("./temp_icon.png")} alt="icon" loading="lazy" />;
  }
};

export default Icon;
