import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import twitter from "./images/twitter.png";
import youtube from "./images/youtube.png";

const Footer = () => {
  return (
    <footer className="mx-4 my-16 text-gray-600 md:mx-10">
      <div className="md:flex">
        <div className="justify-start w-full md:w-8/12">
          <a
            href="https://www.peacocktv.com/terms"
            target="_blank"
            rel="noreferrer"
            aria-label="Terms of Use ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Terms of Use
          </a>

          <a
            href="https://www.nbcuniversal.com/privacy?brandA=Peacock&amp;intake=Peacock"
            target="_blank"
            rel="noreferrer"
            aria-label="Privacy Policy ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Privacy Policy
          </a>

          <a
            href="https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?intake=Peacock"
            target="_blank"
            rel="noreferrer"
            aria-label="CA Notice ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400 "
          >
            CA Notice
          </a>

          <a
            href="https://www.nbcuniversal.com/privacy/cookies/"
            target="_blank"
            rel="noreferrer"
            aria-label="Ad Choices ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Ad Choices
          </a>

          <a
            href="https://www.nbcuniversal.com/privacy/notrtoo?brandA=Peacock&amp;intake=Peacock"
            target="_blank"
            rel="noreferrer"
            aria-label="Do Not Sell My Personal Information ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Do Not Sell My Personal Information
          </a>

          <a
            href="https://together.nbcuni.com/advertise/?utm_source=peacock&amp;utm_medium=referral&amp;utm_campaign=property_ad_pages"
            target="_blank"
            rel="noreferrer"
            aria-label="Advertise with Us ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Advertise with Us
          </a>

          <a
            href="https://www.peacocktv.com/help"
            target="_blank"
            rel="noreferrer"
            aria-label="Help ((Opens in new window))"
            className="block mb-1 mr-5 text-sm transition-colors md:inline-block hover:text-yellow-400"
          >
            Help
          </a>
        </div>

        <div className="justify-end w-full my-6 md:my-0 md:flex md:w-4/12">
          <a
            href="https://www.facebook.com/peacocktv"
            target="_blank"
            rel="noreferrer"
            title="Facebook"
            aria-label="Facebook ((Opens in new window))"
            className="inline-block mr-6 bg-top bg-no-repeat bg-cover pr-9 w-9 h-9 hover:bg-bottom"
            style={{ backgroundImage: `url(${facebook}) ` }}
          >
            {""}
          </a>

          <a
            href="https://twitter.com/peacocktv"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
            aria-label="Twitter ((Opens in new window))"
            className="inline-block mr-6 bg-top bg-no-repeat bg-cover pr-9 w-9 h-9 hover:bg-bottom"
            style={{ backgroundImage: `url(${twitter}) ` }}
          >
            {""}
          </a>

          <a
            href="https://www.youtube.com/c/peacocktv"
            target="_blank"
            rel="noreferrer"
            title="YouTube"
            aria-label="YouTube ((Opens in new window))"
            className="inline-block mr-6 bg-top bg-no-repeat bg-cover pr-9 w-9 h-9 hover:bg-bottom"
            style={{ backgroundImage: `url(${youtube}) ` }}
          >
            {""}
          </a>

          <a
            href="https://www.instagram.com/peacocktv"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
            aria-label="Instagram ((Opens in new window))"
            className="inline-block bg-top bg-no-repeat bg-cover pr-9 w-9 h-9 hover:bg-bottom"
            style={{ backgroundImage: `url(${instagram}) ` }}
          >
            {""}
          </a>
        </div>
      </div>

      <p className="w-full my-6 text-xs">
        Peacock © Peacock TV LLC. All other programs and/or marks are the
        property of their respective owners. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
