import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import Modal from "react-modal";
import Loader from "./Loader";
import Icon from "./Icon";

import IconElement from "./IconElement";
import peacocktv from "./images/peacocktv.svg";

import LEAGUES from "./Leagues";
import TEAMS from "./Teams";
import SPORTS from "./Sports";

import Footer from "./Footer";

Modal.setAppElement("#root");

const PREFERENCES_ID = window.location.search.split("?")[1];

let BASE_URL = `https://${window.location.host}/sport-preference/${PREFERENCES_ID}`;

if (process.env.NODE_ENV === "development") {
  BASE_URL = `https://sport-preferences.pickaxe.ai/sport-preference/${PREFERENCES_ID}`;
}

const App = () => {
  const methods = useForm({ defaultValues: {} });

  const [preferences, setPreferences] = useState({
    sports_league_preferences: [],
    sports_team_preferences: [],
    sports_preferences: [],
  });

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState({
    type: null,
    value: false,
  });
  const [isSavingPreferences, setSavingPreferences] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(BASE_URL, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        setPreferences(response.data);

        // Default form values
        methods.reset(convertRequest(response.data));
      })
      .catch((e) => {
        setLoading(false);
        setError({
          type: "loading",
          value: true,
        });
      });
  }, [methods]);

  const convertRequest = (data) => {
    let preferences = [];

    Object.entries(data).forEach((item) =>
      item[1].forEach((item) => preferences.push(item))
    );

    const formatted = preferences.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    return formatted;
  };

  const convertResponse = (data) => {
    let template = {
      sports_league_preferences: [],
      sports_team_preferences: [],
      sports_preferences: [],
    };

    Object.entries(data).forEach(([id, value]) => {
      if (!value || id === "college") return; // Exclude 'college' since it does not have a matching sport under the LEAGUES

      if (SPORTS.some((o) => o.id === id)) {
        template.sports_preferences.push(id);
        return;
      }

      if (LEAGUES.some((o) => o.id === id)) {
        template.sports_league_preferences.push(id);
        return;
      }

      template.sports_team_preferences.push(id);
    });

    return template;
  };

  const [modalTeams, setModalTeamsOpen] = useState(false);
  const [modalDone, setModalDoneOpen] = useState(false);
  const [modalError, setModalErrorOpen] = useState(false);
  const [currentLeague, setCurrentLeague] = useState();

  const onSubmit = (data) => {
    setSavingPreferences(true);
    setModalDoneOpen(true);
    const preferences = {};

    Object.keys(data).filter((key) => {
      if (data[key] && key !== "undefined") {
        return (preferences[key] = true);
      } else {
        return null;
      }
    });

    axios
      .post(BASE_URL, convertResponse(preferences))
      .then(() => {
        setSavingPreferences(false);
      })
      .catch((error) => {
        setSavingPreferences(false);
        setError({
          type: "saving",
          value: true,
        });
      });
  };

  const closeModal = () => {
    setModalTeamsOpen(false);
    setModalDoneOpen(false);
    setModalErrorOpen(false);

    setCurrentLeague(null);
  };

  const handleLeaguePreferences = (id, league, sport, event) => {
    const hasAtLeatOneTeam = preferences.sports_team_preferences.find((team) =>
      team.toLowerCase().includes(id)
    );

    if (hasAtLeatOneTeam) {
      event.preventDefault();
    }

    let newPreferences = { ...preferences };
    const isSelected = newPreferences.sports_league_preferences.includes(id);

    if (isSelected) {
      // Can't remove a league if it has teams selected
      if (hasAtLeatOneTeam) {
        setModalErrorOpen({
          open: true,
          message:
            "You can't remove a league if you have selected at least one team.",
        });
      } else {
        // Remove
        newPreferences.sports_league_preferences =
          newPreferences.sports_league_preferences.filter(
            (league) => league !== id
          );
        methods.setValue(league, false);
        setPreferences(newPreferences);

        if (hasAtLeatOneTeam) {
          methods.setValue(league, false);
        }
      }
    } else {
      // Add
      if (!newPreferences.sports_league_preferences.includes(id)) {
        newPreferences.sports_league_preferences.push(id);
        setPreferences(newPreferences);
        methods.setValue(id, true);
      }
    }
  };

  const renderLeaguePreferences = () =>
    LEAGUES.sort((a, b) => a["name"].localeCompare(b["name"])).map(
      (league, index) => {
        const teams = preferences.sports_team_preferences.filter((team) =>
          team.includes(league.name)
        );

        if (teams.length > 0) {
          return (
            <div key={index}>
              <IconElement
                eventHandler={handleLeaguePreferences}
                icon={league.icon}
                name={league.league_friendly_name}
                id={league.id}
                size={"lg"}
              />
              <div
                className="relative flex items-center justify-center space-x-1 cursor-pointer top-1"
                onClick={() => {
                  setModalTeamsOpen(true);
                  setCurrentLeague(league.id);
                }}
              >
                {[0, 1, 2].map((_, index) => {
                  if (teams[index]) {
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-center w-1/3 rounded-sm h-14 bg-zinc-900"
                      >
                        <div className="w-8">
                          <Icon
                            icon={`Teams/${league.id}/${teams[index]}.png`}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setModalTeamsOpen(true);
                          setCurrentLeague(league.id);
                        }}
                        className="w-1/3 rounded-sm h-14 bg-zinc-900"
                      ></div>
                    );
                  }
                })}
              </div>
            </div>
          );
        } else {
          return (
            <div key={index}>
              <IconElement
                eventHandler={handleLeaguePreferences}
                icon={league.icon}
                name={league.league_friendly_name}
                id={league.id}
                size={"lg"}
              />
              <div
                onClick={() => {
                  setModalTeamsOpen(true);
                  setCurrentLeague(league.id);
                }}
                className="relative flex space-x-1 space-y-1 cursor-pointer top-1"
              >
                <div className="absolute w-full text-xs text-zinc-400 top-6">
                  Select Your Favorites
                </div>
                <div className="w-1/3 rounded-sm h-14 bg-zinc-900"></div>
                <div className="w-1/3 rounded-sm h-14 bg-zinc-900"></div>
                <div className="w-1/3 rounded-sm h-14 bg-zinc-900"></div>
              </div>
            </div>
          );
        }
      }
    );

  // Teams
  const handleTeamsPreferences = (id, league, sport, event) => {
    let newPreferences = { ...preferences };

    const currentCount = preferences.sports_team_preferences.filter((league) =>
      league.includes(currentLeague.toUpperCase())
    ).length;

    if (!event.target.checked && currentCount <= 3) {
      // Remove
      newPreferences.sports_team_preferences =
        newPreferences.sports_team_preferences.filter((sport) => sport !== id);

      setPreferences(newPreferences);
    } else {
      if (currentCount === 3) {
        event.preventDefault();
        setModalErrorOpen({
          open: true,
          message: "Please select up to 3 teams.",
        });
        methods.setValue(id, false);
      } else {
        // Add Team

        if (!newPreferences.sports_team_preferences.includes(id)) {
          newPreferences.sports_team_preferences.push(id);
          setPreferences(newPreferences);
        }

        // Add and select corresponding League
        if (!newPreferences.sports_league_preferences.includes(league)) {
          newPreferences.sports_league_preferences.push(league);
          setPreferences(newPreferences);
          methods.setValue(league, true);
        }

        // Add and select corresponding Sport
        if (!newPreferences.sports_preferences.includes(sport)) {
          newPreferences.sports_preferences.push(sport);
          setPreferences(newPreferences);
          methods.setValue(sport, true);
        }
      }
    }
  };

  const renderTeamsPreferences = () => {
    if (TEAMS[currentLeague + "_teams"]) {
      return TEAMS[currentLeague + "_teams"]
        .sort((a, b) => a["name"].localeCompare(b["name"]))
        .map((team, index) => {
          const isSelected = preferences.sports_team_preferences.includes(
            team.id
          );
          return (
            <IconElement
              key={index + "__" + team.id}
              eventHandler={handleTeamsPreferences}
              isSelected={isSelected}
              icon={team.icon}
              name={team.name}
              id={team.id}
              league={team.league}
              sport={team.sport}
              size={"sm"}
            />
          );
        });
    }
  };

  // Sports
  const handleSportsPreferences = (id, league, sport, event) => {
    let newPreferences = { ...preferences };
    const teams = TEAMS[league + "_teams"];

    if (!event.target.checked) {
      // Check linked teams
      let sportWithLinkedTeams;

      if (preferences.sports_team_preferences.length && teams) {
        sportWithLinkedTeams = preferences.sports_team_preferences.some(
          (team) => teams.find((o) => o.id === team)
        );
      }

      // Sport can be removed only if it's not linked with any league
      if (sportWithLinkedTeams) {
        event.preventDefault();
        setModalErrorOpen({
          open: true,
          message:
            "You can't remove a sport if you have selected at least one team from its league.",
        });
      } else {
        // Remove
        newPreferences.sports_preferences =
          newPreferences.sports_preferences.filter((sport) => sport !== id);

        setPreferences(newPreferences);
      }
    } else {
      // Add
      if (!newPreferences.sports_preferences.includes(id)) {
        newPreferences.sports_preferences.push(id);
        setPreferences(newPreferences);
      }
    }
  };

  const renderSportPreferences = () =>
    SPORTS.sort((a, b) => a["name"].localeCompare(b["name"])).map(
      (sport, index) => {
        const isSelected = preferences.sports_preferences.includes(sport.id);

        return (
          <IconElement
            key={index}
            eventHandler={handleSportsPreferences}
            isSelected={isSelected}
            icon={sport.icon}
            name={sport.name}
            id={sport.id}
            sport={sport.sport}
            league={sport.league}
            size={"md"}
          />
        );
      }
    );

  if (isLoading) {
    return (
      <div className="grid items-center justify-center h-screen">
        <div>
          <p className="text-white">Loading Your Preference Center</p>
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (isError.type === "loading" && isError.value) {
    return (
      <div className="grid items-center justify-center h-screen">
        <div>
          <p className="mb-4 text-white">Couldn't Load Preference Center</p>
          <div className="flex items-center justify-center">
            <button
              onClick={() => window.location.reload(false)}
              className="btn"
            >
              Refresh and Try again.
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isError.type === "saving" && isError.value) {
    return (
      <div className="grid items-center justify-center h-screen">
        <div>
          <p className="mb-4 text-white">Couldn't Save Your Preferences.</p>
          <div className="flex items-center justify-center">
            <button
              onClick={() => window.location.reload(false)}
              className="btn"
            >
              Refresh and Try again.
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <nav className="sticky top-0 z-30 bg-black">
        <div className="flex items-center justify-center max-w-screen-lg px-4 py-4 ml-auto mr-auto text-white md:px-14">
          <div className="mr-auto text-left">
            <img
              src={peacocktv}
              alt="peacocktv"
              className="w-24 text-white md:w-36 w-100 fill-white"
            />
          </div>
          <div className="ml-auto text-right">
            <button
              type="submit"
              form="PreferencesForm"
              className="bg-yellow-300 hover:bg-white btn"
            >
              Save <span className="hidden md:inline-block">Preferences</span>
            </button>
          </div>
        </div>
      </nav>

      <div className="max-w-screen-lg p-4 ml-auto mr-auto text-white">
        <form onSubmit={methods.handleSubmit(onSubmit)} id="PreferencesForm">
          <main className="text-center">
            <h1 className="my-5 text-5xl font-extrabold">Pick Your Faves</h1>

            <p className="max-w-lg mb-10 ml-auto mr-auto text-md">
              Keep up with the teams and sports that you love. Get updates on
              what's happening and what's ahead.
            </p>

            <div className="flex flex-wrap items-center justify-center gap-5 md:flex-nowrap md:gap-10">
              {renderLeaguePreferences()}
            </div>

            <p className="mt-14 mb-14">More sports you can follow.</p>

            <div className="flex flex-wrap justify-center gap-2">
              {renderSportPreferences()}
            </div>
          </main>

          <Modal
            isOpen={modalTeams}
            onRequestClose={closeModal}
            className="absolute top-0 left-0 right-0 z-40 w-full ml-auto mr-auto overflow-auto bg-black border-none outline-none md:border-yellow-400 md:rounded-md md:border-1 md:w-8/12 lg:w-1/2 md:left-4 md:right-4 bottom-4 md:top-5"
            overlayClassName="fixed z-30 top-0 left-0 right-0 bottom-0 bg-gray-800/95 text-white"
          >
            <main>
              <div className="sticky top-0 z-50 flex w-full h-20 p-5 mb-2 bg-black">
                <div className="justify-center">
                  <div className="w-12">
                    <Icon
                      icon={
                        LEAGUES.find(
                          (league) => league.id === `${currentLeague}`
                        )?.icon
                      }
                      name={
                        LEAGUES.find(
                          (league) => league.id === `${currentLeague}`
                        )?.name
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center w-full px-5 text-xs text-center md:text-sm lg:text-md">
                  Select up to 3 of your favorite teams to follow.
                </div>
                <div className="justify-center">
                  <button
                    type="submit"
                    form="PreferencesForm"
                    className="bg-yellow-300 hover:bg-white btn"
                  >
                    Save
                  </button>
                </div>
              </div>
              {TEAMS[currentLeague + "_teams"] ? (
                <div className="flex flex-wrap items-center justify-center gap-4 mx-2 mt-2">
                  {currentLeague && renderTeamsPreferences(currentLeague)}
                </div>
              ) : (
                <p className="text-center text-white">No Teams</p>
              )}
            </main>
          </Modal>

          <Modal
            isOpen={modalDone}
            onRequestClose={closeModal}
            className="absolute z-50 w-11/12 transform -translate-x-1/2 -translate-y-1/2 bg-black border border-yellow-400 rounded-md outline-none md:w-auto top-1/2 left-1/2 focus:ring-0"
            overlayClassName="fixed z-40 top-0 left-0 right-0 bottom-0 bg-gray-800/95 text-white"
          >
            <main className="relative">
              {isSavingPreferences ? (
                <div className="p-10 text-center">
                  <p> Saving Your Preferences...</p>
                  <div className="flex items-center justify-center">
                    <Loader />
                  </div>
                </div>
              ) : (
                <div className="p-10 text-left">
                  <div className="mb-6">
                    <h2 className="mb-5 text-md md:text-4xl">You're In!</h2>
                    <p className="mb-5 text-lg">
                      All the updates about your favorite teams will be sent
                      directly to your inbox.
                    </p>
                    <p className="text-xs">
                      Come back to this page at any time to update your
                      preferences.
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setModalDoneOpen(false);
                      setModalTeamsOpen(false);
                      setModalErrorOpen({
                        open: false,
                        message: "",
                      });
                    }}
                    className="absolute btn top-5 right-5"
                  >
                    Close
                  </button>
                </div>
              )}
            </main>
          </Modal>

          <Modal
            isOpen={modalError.open}
            onRequestClose={closeModal}
            className="absolute z-50 w-11/12 transform -translate-x-1/2 -translate-y-1/2 bg-black border border-yellow-400 rounded-md outline-none md:w-auto top-1/2 left-1/2 focus:border-current focus:ring-0"
            overlayClassName="fixed z-50 top-0 left-0 right-0 bottom-0 bg-gray-800/95 text-white"
          >
            <main className="relative">
              <div className="p-10 text-center">
                <div className="mt-10 mb-6">{modalError.message}</div>

                <button
                  onClick={() => {
                    setModalDoneOpen(false);
                    setModalErrorOpen({
                      open: false,
                      message: "",
                    });
                  }}
                  className="absolute btn top-5 right-5"
                >
                  Close
                </button>
              </div>
            </main>
          </Modal>
        </form>
        <Footer />
      </div>
    </FormProvider>
  );
};

export default App;
