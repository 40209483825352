import { useFormContext } from "react-hook-form";

import classNames from "classnames";
import check from "./images/check.svg";
import Icon from "./Icon";

const IconElement = ({ eventHandler, name, id, league, sport, icon, size }) => {
  const { register } = useFormContext();

  const isLarge = size === "lg" ? true : false;
  const isMedium = size === "md" ? true : false;
  const isSmall = size === "sm" ? true : false;

  return (
    <div className="flex flex-col items-center justify-center cursor-pointer">
      <input
        type="checkbox"
        {...register(id)}
        id={id}
        className="hidden appearance-none input-checkbox"
        onClick={(event) => eventHandler(id, league, sport, event)}
      />
      <label
        htmlFor={id}
        className={classNames(
          "relative block cursor-pointer border  rounded-xl bg-zinc-900",
          {
            "w-20 h-20 md:w-24 md:h-24 border-zinc-600": isSmall,
            "w-36 h-36 border-zinc-900": isMedium,
            "w-44 h-44 border-zinc-900": isLarge,
          }
        )}
      >
        <div className="absolute flex items-center justify-center invisible w-4 h-4 text-black bg-yellow-400 rounded-full checkbox-check top-1 right-1">
          <img src={check} alt="✔" className="w-3 h-3" loading="lazy" />
        </div>

        <div
          className={classNames("absolute", {
            "w-14 h-14 left-3 top-3 md:left-5 md:top-5": isSmall,
            "w-[5rem] h-[4rem] left-8 top-6": isMedium,
            "w-20 h-20 left-12 top-8": isLarge,
          })}
        >
          <Icon
            icon={icon}
            className={classNames("absolute ", {
              "w-10 h-10": isSmall,
              "w-12 h-12": isMedium,
              "w-16 h-16": isLarge,
            })}
          />
        </div>

        {!isSmall && (
          <p
            className={classNames("absolute w-full px-2 text-center", {
              "text-xs bottom-1": isSmall,
              "text-sm bottom-4": isMedium,
              "text-md bottom-4": isLarge,
            })}
          >
            {name}
          </p>
        )}
      </label>
    </div>
  );
};

export default IconElement;
