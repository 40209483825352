const SPORTS = [
  // {
  //   id: "baseball",
  //   name: "Baseball",
  //   icon: "Sports/Baseball.png",
  // },
  {
    id: "basketball",
    name: "Basketball",
    icon: "Sports/Basketball.png",
  },
  // {
  //   id: "beach_volleyball",
  //   name: "Beach Volleyball",
  //   icon: "Sports/BeachVolleyball.png",
  // },
  // { id: "curling", name: "Curling", icon: "Sports/Curling.png" },
  { id: "cycling", name: "Cycling", icon: "Sports/Cycling.png" },
  // {
  //   id: "figure_skating",
  //   name: "Figure Skating",
  //   icon: "Sports/FigureSkating.png",
  // },
  {
    id: "football",
    name: "Football",
    icon: "Sports/Football.png",
    league: "nfl",
  },
  { id: "golf", name: "Golf", icon: "Sports/Golf.png" },
  {
    id: "horse_racing",
    name: "Horse Racing",
    icon: "Sports/HorseRacing.png",
  },
  // { id: "imsa", name: "IMSA", icon: "Sports/IMSA.png" },
  // { id: "indycar", name: "Indy Car", icon: "Sports/IndyCar.png" },
  // {
  //   id: "motorcross",
  //   name: "Motorcross",
  //   icon: "Sports/Motorcross.png",
  // },
  {
    id: "notre_dame",
    name: "Notre Dame",
    icon: "Sports/NotreDame.png",
  },
  {
    id: "motor_sports",
    name: "Motor Sports",
    icon: "Sports/Motorsports.png",
  },
  { id: "rugby", name: "Rugby", icon: "Sports/Rugby.png" },
  // {
  //   id: "speed_skating",
  //   name: "Speed Skating",
  //   icon: "Sports/SpeedSkating.png",
  // },
  {
    id: "swimming",
    name: "Swimming",
    icon: "Sports/Swimming.png",
  },
  {
    id: "skiing_snowboarding",
    name: "Skiing & Snowboarding",
    icon: "Sports/Skiing_Snowboarding.png",
  },

  // {
  //   id: "supercross",
  //   name: "Supercross",
  //   icon: "temp_icon.png",
  // },
  {
    id: "skating",
    name: "Skating",
    icon: "Sports/Skating.png",
    league: "epl",
  },
  {
    id: "soccer",
    name: "Soccer",
    icon: "Sports/Soccer.png",
    league: "epl",
  },
  { id: "tennis", name: "Tennis", icon: "Sports/Tennis.png" },
  {
    id: "track_field",
    name: "Track & Field",
    icon: "Sports/TrackField.png",
  },
  // {
  //   id: "snow_sports",
  //   name: "Snow Sports",
  //   icon: "Sports/SnowSports.png",
  // },
];

export default SPORTS;
