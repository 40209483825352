const Loader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90px"
      height="90px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(14.285714285714286 50)">
        <circle cx="0" cy="0" r="6" fill="#fbcb33">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.4251700680272109s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(28.571428571428573 50)">
        <circle cx="0" cy="0" r="6" fill="#fd7128">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3401360544217687s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(42.857142857142854 50)">
        <circle cx="0" cy="0" r="6" fill="#ed1c46">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.25510204081632654s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(57.142857142857146 50)">
        <circle cx="0" cy="0" r="6" fill="#6E5AD9">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.17006802721088435s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(71.42857142857143 50)">
        <circle cx="0" cy="0" r="6" fill="#1C9EDE">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.08503401360544217s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(85.71428571428571 50)">
        <circle cx="0" cy="0" r="6" fill="#1BAB45">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  );
};

export default Loader;
